import Typo from '@mui/material/Typography'
import {Link} from 'gatsby'
import Box from 'hendrixmaterial/box'
import React from 'react'
import {Title} from '../components'
import {makeDialog} from '../makeDialog'



const content=[
  'Design and build an amusement park',
  'Explore how centrifugal and centripetal forces are at work on amusement park rides',
  'Build a Ferris wheel using gears',
  'Explore the science behind how to blow a bubble gum bubble',
  <>Download the pages from <Link to='/activities'>activities</Link></>,
  <>Explore the <Link to='/games'>games</Link></>,
  'Design an original Penelope Penny book cover',
  'Put on a musical recital'
]

const _title='STEAM'
const _subtitle='Science, Technology, Engineering, Arts, Mathematics'

export const dialogSteam=makeDialog({
  page:'curriculum',
  hash:'steam',
  buttonProps:{
    children:_title,
  },
  dialogTitleProps:{
    dialogTitleProps:{
      children:<Title title={_title} subTitle={_subtitle}/>,
    },
  },
  dialogContentProps:{
    children:<Box>
      {content.map((o,i)=><React.Fragment key={i}><Typo>• {o}</Typo><br/></React.Fragment>)}
    </Box>,
  },
})

export default dialogSteam
