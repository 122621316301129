import React from 'react'
import Typo from'@mui/material/Typography'

import Box from'hendrixmaterial/box'
import{makeDialog}from'../makeDialog'
import{Title}from'../components'


const content=[
  'Fiction vs Nonfiction',
  'Common elements and themes of a cozy mystery, such as: female protagonist who solves a mystery on her own or with the help of friends; comfortable setting; good friends; sense of community; slightly evil antagonist; satisfying ending with the problem solved',
  'Compare and contrast the cozy mystery to other works of fiction',
  'Compare and contrast the cozy mystery to a standard mystery'
]

const _title='Genre Study'
const _subtitle='Ideas for incorporating Genre Study'


export const dialogGenreStudy=makeDialog({
  page:'curriculum',
  hash:'genre-study',
  buttonProps:{
    children:_title,
  },
  dialogTitleProps:{
    dialogTitleProps:{
      children:<Title title={_title} subTitle={_subtitle}/>,
    },
  },
  dialogContentProps:{
    children:<Box>
      {content.map((o,i)=><React.Fragment key={i}><Typo>• {o}</Typo><br/></React.Fragment>)}
    </Box>,
  },
})

export default dialogGenreStudy
