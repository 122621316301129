export const baseButtonProps={
  color:'primary',
  size:'large',
  variant:'outlined'
}

export const baseDialogProps={
  // scroll:'body'
}

export const baseDialogTitleProps={
  sx:{
    color:theme=>theme.palette.primary.main
  }
}

export const baseDialogContentProps={
  dividers:true
}
