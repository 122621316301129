import React from 'react'
import Typo from'@mui/material/Typography'

import Box from'hendrixmaterial/box'
import{makeDialog}from'../makeDialog'
import{Title}from'../components'


const content=[
  'Choose a chapter and act it out in front of the class',
  'Make up an original scene with dialogue and act it out',
  'Create an original cozy mystery play',
  'Write a poem about a character and present it',
  'Present a magic trick'
]

const _title='Presentation'
const _subtitle='Public speaking, Theater'

export const dialogPresentation=makeDialog({
  page:'curriculum',
  hash:'presentation',
  buttonProps:{
    children:_title,
  },
  dialogTitleProps:{
    dialogTitleProps:{
      children:<Title title={_title} subTitle={_subtitle}/>,
    },
  },
  dialogContentProps:{
    children:<Box>
      {content.map((o,i)=><React.Fragment key={i}><Typo>• {o}</Typo><br/></React.Fragment>)}
    </Box>,
  },
})

export default dialogPresentation
