import React from 'react'
import Typo from'@mui/material/Typography'

import Box from'hendrixmaterial/box'
import{makeDialog}from'../makeDialog'
import{Title}from'../components'

import * as Is from'is'


const tab2=<>&ensp;</>
const tab4=<>&emsp;</>
const tab     =<>{tab2}{tab4}</>
const tabLarge=<>{tab}{tab4}</>


const content=[
  {
    title:'Story Elements',
    content:[
      {
        subTitle:'Characters',
        subContent:[
          'Identify the main and minor characters',
          'Protagonist (hero) vs antagonist (villain)',
          'Draw a character using context clues or imagination',
          'How do the characters change throughout the story?',
          'What are some characteristics of Penelope?'
        ],
        spacing:tab
      },
      {
        subTitle:'Setting'
      },
      {
        subTitle:'Plot--conflict and resolution'
      },
      {
        subTitle:'Themes',
        subContent:[
          {
            description:'Magic and intrigue',
            content:[
              'Would you like a magical pet?',
              'What conversation would you have with a pet if it could talk?',
              'Madam Crioux is a mysterious fortune teller. How did she know Penelope?',
              'What magic would you include in a story?'
            ]
          },
          {
            description:'Good vs evil, Friendship, Hope'
          },
        ]
      }
    ],
  },

  {
    title:'Grammar/Phonics',
    content:[
      'Synonyms and antonyms',
      'Homophones, homonyms, and rhyming words (Crioux rhymes with Sioux)',
      'Dialogue and punctuation',
      'Parts of speech and their roles in a sentence',
      'Sentence and paragraph structure',
      'Contractions and apostrophes for ownership (ie Cuddles’s)',
      'Blends and digraphs'
    ],
    spacing:tab
  },

  {
    title:'Vocabulary',
    content:[
      'For any unknown words use decoding strategies, such as breaking it down and making use of syllables, sound it out, reread, use prior knowledge, and look for context clues',
      'Keep a log of new vocabulary words (ex: rhetorical, mesmerize)'
    ],
    spacing:tab
  },

  {
    title:'Making Connections',
    content:[
      {
        subContent:[
          {
            description:'Making a personal connection to a character or part of the story can increase comprehension and interest:',
            content:[
              'Have you ever been the new student?',
              'Do you have a pet?',
              'Do you play a musical instrument?',
              'Have you ever been to an amusement park?',
              'Austin’s bubble accidentally bursts on Jennie’s dress and he feels terrible. Has something like this ever happened to you?',
              'Jennie did not accept Austin’s apology at first. Have you apologized and was it accepted? (how to apologize could be discussed here)',
              'Have you ever performed on stage?'
            ],
            spacing:tab
          }
        ]
      }
    ]
  }
]

const title='Reading'
const subTitle='Aspects for Reading study'

export const dialogReading=makeDialog({
  page:'curriculum',
  hash:'reading',
  buttonProps:{
    children:title,
  },
  dialogTitleProps:{
    dialogTitleProps:{
      children:<Title title={title} subTitle={subTitle}/>,
    },
  },
  dialogContentProps:{
    children:<Box>
      {content.map((o,i)=><React.Fragment key={i+'-content'}>
        <Typo variant='subtitle2'>• {o.title+'\n'}</Typo>
        {o?.content?.map((c,i2)=>{
          const SubTitle=({title})=><i key={i+i2+'-i2title'}><Typo>{tab}{title}</Typo></i>
          const SubDescription=({description,spacing=tabLarge})=><Typo key={i+i2+'-i2description'}>{spacing}{description}</Typo>
          const SubContent=({content,spacing=tabLarge})=><Typo key={i+i2+'-i2content'}>{spacing}{content}</Typo>

          if(Is.object(c)){
            const _subtitle=c?.subTitle&&<SubTitle title={c.subTitle}/>
            const _subcontent=c?.subContent?.map((s,i)=>{
              if(Is.object(s)){
                return <React.Fragment key={i+i2+'-isObjectIsObjectWrapper'}>
                  <SubDescription description={s.description} spacing={s?.spacing}/>
                  {s.content?.map((sc,i3)=><SubContent content={<>{tab}{sc}</>} spacing={o?.spacing||c?.spacing||s?.spacing} key={i+i2+i3+'-i3-content'}/>)}
                </React.Fragment>
              }

              if(Is.string(s)){
                return <SubContent content={<>{tab}{s}</>} spacing={o?.spacing||c?.spacing||s?.spacing} key={i+i2+'-isObject-isString'}/>
              }
            })

            return <React.Fragment key={i+i2+'-isObjectWrapper'}>
              {_subtitle}
              {_subcontent}
            </React.Fragment>
          }

          if(Is.string(c)){
            return <SubContent key={i+i2+'-subcontentIsString'} content={c} spacing={o?.spacing||c?.spacing}/>
          }

        })}
        <br/>
      </React.Fragment>)}
    </Box>,
  },
})

export default dialogReading
