import * as React from'react'

import Typo from'@mui/material/Typography'
// import ReactToPrint,{ useReactToPrint } from 'react-to-print';


export const Title=({title,children,subTitle})=><div>
  <Typo variant='h6'>{title||children}</Typo>
  <Typo variant='subtitle1'>{subTitle}</Typo>
</div>


export const Im = React.forwardRef((props, ref) =><img ref={ref} src={props.src} alt='NOTHING' style={{maxWidth:'100%',maxHeight:'100%',cursor:'pointer'}} onClick={props.onClick}/>);

export const PrintIm=({src,...props})=>{
  // const ref=React.useRef()
  // const handlePrint = useReactToPrint({
  //   content: () =>{
  //     console.log('content',ref.current)
  //     return ref.current
  //   },
  //   onBeforeGetContent:e=>console.log('before get content',ref.current),
  //   onBeforePrint:e=>console.log('before print',ref.current),
  //   onAfterPrint:e=>console.log('after print',ref.current),
  //   onPrintError:e=>console.log('on error',ref.current),
  //   // removeAfterPrint:true
  // });

  // return <Im src={src}/>
  // return <Im ref={ref} src={src} onClick={handlePrint}/>



  // const ref = React.useRef();
  // // const handlePrint = useReactToPrint({
  // //   content: () => ref.current,
  // //   onBeforeGetContent:e=>console.log('before get content',e),
  // //   onBeforePrint:e=>console.log('before print',e),
  // //   onAfterPrint:e=>console.log('after print',e),
  // //   onPrintError:e=>console.log('on error',e),
  // //   removeAfterPrint:true
  // // });

  return <Im src={src} onClick={()=>window.open(src)}/>


  // return (
  //   <div>
  //     <ReactToPrint
  //       trigger={(e) =>{
  //         console.log('trigger',e)
  //         return <button>Print this out!</button>
  //       }}
  //       content={(e) =>{
  //         console.log('content',e,ref?.current)
  //         if(ref?.current){
  //           console.log('has current',ref.current)
  //         }
  //         if(!ref?.current){
  //           console.log('no current',ref?.current)
  //         }
  //         return ref.current
  //       }}
  //     />
  //     <Im ref={ref} src={src}/>
  //   </div>
  // );

  // return <Box ref={ref} onClick={handlePrint}>
  //   <Im src={src}/>
  // </Box>
}


// export const PrintIm=({src,...props})=>{
//   const ref = React.useRef();
//   const handlePrint = useReactToPrint({
//     content: () => ref.current,
//     onBeforeGetContent:e=>console.log('before get content',e),
//     onBeforePrint:e=>console.log('before print',e),
//     onAfterPrint:e=>console.log('after print',e),
//     onPrintError:e=>console.log('on error',e),
//     removeAfterPrint:true
//   });

//   // return <img ref={ref} src={props.src} alt='' style={{maxWidth:'100%',maxHeight:'100%',cursor:'pointer'}} onClick={props.onClick}/>

//   return <Im ref={ref} src={src} onClick={handlePrint}/>
//   // return <Box ref={ref} onClick={handlePrint}>
//   //   <Im src={src}/>
//   // </Box>
// }



// export const PrintIm=React.forwardRef(({src,...props},ref)=>{
//   // const ref = React.useRef();
//   const handlePrint = useReactToPrint({
//     content: () => ref.current,
//     onBeforeGetContent:e=>console.log('before get content',e),
//     onBeforePrint:e=>console.log('before print',e),
//     onAfterPrint:e=>console.log('after print',e),
//     onPrintError:e=>console.log('on error',e),
//     removeAfterPrint:true
//   });

//   return <img ref={ref} src={props.src} alt='' style={{maxWidth:'100%',maxHeight:'100%',cursor:'pointer'}} onClick={props.onClick}/>

//   // return <Im ref={ref} src={src} onClick={handlePrint}/>
//   // return <Box ref={ref} onClick={handlePrint}>
//   //   <Im src={src}/>
//   // </Box>
// })
