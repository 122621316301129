import{makePage}from'../makePage'

import genreStudy from'./genreStudy'
import presentation from'./presentation'
import reading from'./reading'
import steam from'./steam'
import writing from'./writing'

const PageCurriculum=(props)=>makePage({content:[genreStudy,presentation,reading,steam,writing],...props})

export default PageCurriculum
