import { navigate } from 'gatsby'
import{DialogProps}from'hendrixmaterial/dialog'
import{baseButtonProps,baseDialogProps,baseDialogTitleProps,baseDialogContentProps}from'./base'


export interface makeDialogProps extends DialogProps{
  page:'string',
  hash:'string'
}

export const makeDialog=(props:makeDialogProps)=>({
  ...props,
  openOnHash:props?.hash,
  buttonProps:{
    ...baseButtonProps,
    ...props?.buttonProps,
    onClick:()=>navigate(`/${props.page}#${props.hash}/`)
  },
  dialogProps:{
    ...baseDialogProps,
    ...props?.dialogProps,
      onClose:()=>navigate(`/${props.page}/`),
  },
  dialogTitleProps:{
    dialogTitleProps:{
      ...baseDialogTitleProps,
      ...props?.dialogTitleProps?.dialogTitleProps
    },
    ...props?.dialogTitleProps?.iconButtonProps,
    ...props?.dialogTitleProps?.withClose,
  },
  dialogContentProps:{
    ...baseDialogContentProps,
    ...props?.dialogContentProps
  },
  dialgActionProps:{
    ...props?.dialogActionProps
  }
})
