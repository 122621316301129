import React from 'react'
import Typo from'@mui/material/Typography'

import Box from'hendrixmaterial/box'
import{makeDialog}from'../makeDialog'
import{Title}from'../components'


const content=[
  'Write a letter to one of the characters',
  'What color would you use to describe Penelope and why?',
  'Choose an option from making connections (see Reading) and write your response',
  'Describe the steps involved with making your own amusement park',
  'Write an original chapter of cozy mystery fiction',
  'Use two new vocabulary words (see Reading) in a sentence',
  'Use four new vocabulary words (see Reading) in a paragraph',
  'Write an original cozy mystery'
]

const _title='Writing'
const _subtitle='Ideas for incorporating Writing'

export const dialogWriting=makeDialog({
  page:'curriculum',
  hash:'writing',
  buttonProps:{
    children:_title,
  },
  dialogTitleProps:{
    dialogTitleProps:{
      children:<Title title={_title} subTitle={_subtitle}/>,
    },
  },
  dialogContentProps:{
    children:<Box>
      {content.map((o,i)=><React.Fragment key={i}><Typo>• {o}</Typo><br/></React.Fragment>)}
    </Box>,
  },
})

export default dialogWriting
